import { alpha, Button, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Container from '@mui/material/Container';
import { useEffect, useState } from 'react';
import { configHack } from '../../config';
import { useParams } from 'react-router-dom';

interface Details {
  [key: string]: string[];
}

interface PriceDetail {
  price: number;
  time: string;
}

interface Data {
  hackName: string;
  hackPrice: number;
  path: string;
  image: string;
  details?: Details;
  priceDetails?: PriceDetail[];
}

export default function HackValorantDetails() {
  const { hackId } = useParams<{ hackId: string }>();
  const [data, setData] = useState<Data | undefined>();

  useEffect(() => {
    document.title = `YADERNYY - ${hackId}`;
    const dataFilter = configHack.filter((items) => items.path === hackId);
    setData(dataFilter[0]);
  }, [hackId]);

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Grid container justifyContent="space-between">
          <Grid item p={1} xs={12} sm={6}>
            <img width="100%" src={data?.image} style={{ borderRadius: '6px' }} alt="img-hack" />
            {data?.details && (
              <Grid container>
                {Object.keys(data.details).map((key) => (
                  <Grid xs={12} sm={6} key={key}>
                    <Typography fontWeight={700} variant="h6" mt={1} color="primary">
                      {key}
                    </Typography>
                    <ul>
                      {data.details![key].map((detail, index) => (
                        <Typography key={index} mt={0.5} mb={1} ml={-2} textAlign="start" sx={{ userSelect: 'none' }}>
                          👉 {detail}
                        </Typography>
                      ))}
                    </ul>
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h4" color="primary">
              {data?.hackName}
            </Typography>
            {data?.priceDetails && (
              <Grid>
                {data.priceDetails.map((priceDetail, index) => (
                  <Grid
                    container
                    key={index}
                    pb={1}
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ borderBottom: '1px solid' }}
                    mt={1}
                  >
                    <Typography variant="h6">
                      {priceDetail.time} 👉 {priceDetail.price.toLocaleString()} VNĐ
                    </Typography>
                    <Button variant="contained" endIcon={<ShoppingCartIcon />}>
                      Mua
                    </Button>
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
