import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// Định nghĩa kiểu dữ liệu cho props
interface DataItem {
    image: string; // Giả sử image là tên tệp, ví dụ: 'image1.jpg'
    name: string;
    region: string;
    score: number;
}

interface DataListProps {
    data: DataItem[];
}

const OutlinedCard: React.FC<DataListProps> = ({ data }) => {
    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
            {data.map((item, index) => (
                <Card key={index} sx={{ width: { xs: '100%', sm: '200px' } }} variant="outlined">
                    <Box sx={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                        <Box sx={{ background: 'linear-gradient(to bottom, white, yellow)', position: 'absolute', width: '40px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px 10px 20px 20px' }}>
                            <Typography color='#000' fontWeight={700}>
                                <sup>#</sup>{index + 1}
                            </Typography>
                        </Box>
                    </Box>
                    <CardContent sx={{display: 'flex', alignItems: 'center', flexDirection:'column'}}>
                        <Box
                            component="img"
                            src={`/images/valorant/${item.image}`} // Đường dẫn ảnh từ thư mục public
                            alt={item.name}
                            sx={{ width: '100%', height: 'auto', mb: 2 }}
                        />
                        <Typography variant="h5" component="div">
                            {item.name}
                        </Typography>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            {item.region}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            Score: {item.score}
                        </Typography>
                    </CardContent>
                    <CardActions sx={{display: 'flex', alignItems: 'center', flexDirection:'column'}}>
                        <Button size="small">Details</Button>
                    </CardActions>
                </Card>
            ))}
        </Box>
    );
};

export default OutlinedCard;
