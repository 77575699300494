import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { brand } from '../../../getLPTheme';
import './Card.css';
import { formatVND } from '../../../funcion';
import { useNavigate } from 'react-router-dom';

interface CardData {
    hackName: string;
    hackPrice: number;
    path: string;
    image: string;
}
interface CardApp {
    dataCard: CardData
}

const CardHackValorant: React.FC<CardApp> = ({ dataCard }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(dataCard.path);
    }

    return (
        <Grid
            className='box'
            p={0.5}
            xs={12}
            sm={3.8}
            sx={(theme) => ({
                borderRadius: '6px',
                '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: `${brand[600]} 0px 5px 15px`,
                    cursor: 'pointer', // Thêm cursor pointer để hiển thị con trỏ khi hover
                },
            })}
            onClick={handleClick} // Thêm sự kiện onClick
        >
            <img width='100%' src={dataCard?.image} style={{ borderRadius: '6px' }} alt='img-hack' />
            <Typography
                variant="h1"
                textAlign='center'
                sx={{
                    fontSize: 'clamp(1rem, 4vw, 1.5rem)',
                    color: (theme) =>
                        theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                    userSelect: 'none'
                }}
            >
                {dataCard?.hackName}
            </Typography>
            <Typography mt={0.5} mb={1} textAlign='start' sx={{ userSelect: 'none' }}>Giá chỉ từ: {formatVND(dataCard?.hackPrice)}</Typography>
        </Grid>
    );
}

export default CardHackValorant;
