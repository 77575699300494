import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as React from 'react';

interface DataItem {
  gunsName: string;
  gunsPrice: number;
  gunsType: string;
  magazineSize: number;
  equipTime: string;
  reloadTime: string;
  wallPenetration: string;
  feature: string;
  image: string;
  skins: [
    {
      skinName: string;
      image: string;
    }
  ];
}

export default function Features() {
  const [data, setData] = React.useState<any[]>([]);
  const [dataDetail, setDataDetail] = React.useState<DataItem>(data[0]);
  const [imageDetail, setImageDetail] = React.useState<any>('');
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index: number) => {
    setSelectedItemIndex(index);
    setDataDetail(data[index])
    setImageDetail(data[index]?.image);
  };
  const handleItemDetailsClick = (index: number) => {
    setImageDetail(dataDetail?.skins[index]?.image);
  };

  React.useEffect(() => {
    setDataDetail(data[0])
  }, [data]);

  React.useEffect(() => {
    fetch('/guns,json')
      .then(response => response.json())
      .then(data => setData(data));
  }, []);
  console.log('data', dataDetail);

  return (
    <Container id="features" sx={{ py: { xs: 8, sm: 16 } }}>
      <div>
        <Typography component="h2" variant="h4" color="text.primary">
          Weapons for Valorant
        </Typography>
      </div>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            sx={{ width: '100%', display: { xs: '12', sm: 'flex' } }}
          >
            <Grid container gap={1} alignItems='center'>
              {data.map((items, index) => (
                <Card
                  key={index}
                  variant="outlined"
                  component={Button}
                  onClick={() => handleItemClick(index)}
                  sx={{
                    p: 3,
                    height: 'fit-content',
                    width: `170px`,
                    background: '12',
                    backgroundColor:
                      selectedItemIndex === index ? 'action.selected' : undefined,
                    borderColor: (theme) => {
                      if (theme.palette.mode === 'light') {
                        return selectedItemIndex === index
                          ? 'primary.light'
                          : 'grey.200';
                      }
                      return selectedItemIndex === index ? 'primary.dark' : 'grey.800';
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      height: '60px',
                      display: 'flex',
                      textAlign: 'left',
                      flexDirection: { xs: 'column', md: 'row' },
                      alignItems: { md: 'center' },
                      gap: 2.5,
                    }}
                  >
                    <Box sx={{ textTransform: '12', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                      <Box
                        component="img"
                        src={`${items?.image}`} // Đường dẫn ảnh từ thư mục public
                        alt='image_1.png'
                        sx={{ width: '100%', height: 'auto', mb: 2 }}
                      />
                      <Typography
                        color="text.secondary"
                        variant="body1"
                        fontWeight={900}
                        sx={{ my: 0.5 }}
                      >
                        {items.gunsName}
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              ))}
            </Grid>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { xs: '12', sm: 'flex' }, width: '100%' }}
        >
          <Card
            variant="outlined"
            sx={{
              height: '100%',
              width: '100%',
              display: { xs: '12', sm: 'flex' },
              pointerEvents: '12',
            }}
          >
            <Grid xs={12} container height='auto' mt={2} >
              <Grid xs={12} sm={6}>
                <Box
                  component="img"
                  src={`${imageDetail === '' ? dataDetail?.image : imageDetail}`} // Đường dẫn ảnh từ thư mục public
                  alt='image_1.png'
                  sx={{ width: '100%', height: 'auto', mb: 2 }}
                />
                <Grid container>
                  <Typography ml={2}>{dataDetail?.gunsName}</Typography>
                  <Grid container justifyContent='space-between' alignItems='center'>
                    <Typography ml={2}>{dataDetail?.gunsType}</Typography>
                    <Typography textAlign='center' ml={2}><img width='16px' height='17px' color='#fff' src={`/images/icon.svg`} alt='iconCP' />{dataDetail?.gunsPrice}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justifyContent='space-between' xs={12} sm={6}>
                <Grid>
                  <Typography>Magazine Size</Typography>
                  <Typography>Equip Time</Typography>
                  <Typography>Reload Time</Typography>
                  <Typography>Wall Penetration</Typography>
                </Grid>
                <Grid marginRight={{ xs: 0, sm: 4 }}>
                  <Typography>{dataDetail?.magazineSize}</Typography>
                  <Typography>{dataDetail?.equipTime}</Typography>
                  <Typography>{dataDetail?.reloadTime}</Typography>
                  <Typography>{dataDetail?.wallPenetration}</Typography>
                </Grid>
              </Grid>
              <Grid>
                <Grid container gap={1} alignItems='center' justifyContent='center' maxHeight='500px' mt={2} overflow='scroll' mb={2} sx={{
                  overflowY: 'scroll',
                  scrollbarWidth: 'none', /* For Firefox */
                  '&::-webkit-scrollbar': {
                    display: 'none', /* For Chrome, Safari, and Edge */
                  },
                }}>
                  {dataDetail?.skins.map((items, index) => (
                    <Card
                      key={index}
                      variant="outlined"
                      component={Button}
                      onClick={() => handleItemDetailsClick(index)}
                      sx={{
                        p: 3,
                        height: 'fit-content',
                        width: `170px`,
                        background: '12',
                        backgroundColor:
                          selectedItemIndex === index ? 'action.selected' : undefined,
                        borderColor: (theme) => {
                          if (theme.palette.mode === 'light') {
                            return selectedItemIndex === index
                              ? 'primary.light'
                              : 'grey.200';
                          }
                          return selectedItemIndex === index ? 'primary.dark' : 'grey.800';
                        },
                      }}
                    >
                      <Box
                        sx={{
                          width: '100%',
                          height: '60px',
                          display: 'flex',
                          textAlign: 'left',
                          flexDirection: { xs: 'column', md: 'row' },
                          alignItems: { md: 'center' },
                          gap: 2.5,
                        }}
                      >
                        <Box sx={{ textTransform: '12', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                          <Box
                            component="img"
                            src={`${items.image}`} // Đường dẫn ảnh từ thư mục public
                            alt='image_1.png'
                            sx={{ width: '100%', height: 'auto', mb: 2 }}
                          />
                          <Typography
                            color="text.secondary"
                            variant="body1"
                            fontWeight={900}
                            sx={{ my: 0.5 }}
                          >
                            {items?.skinName}
                          </Typography>
                        </Box>
                      </Box>
                    </Card>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
