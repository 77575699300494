import * as React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { PaletteMode } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import LogoCollection from './components/LogoCollection';
import Highlights from './components/Highlights';
import Pricing from './components/Pricing';
import Features from './components/Features';
import Testimonials from './components/Testimonials';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import getLPTheme from './getLPTheme';
import SignUp from './components/SignUp/SignUp'; // Trang khác
import SignIn from './components/SignIn/SignIn'; // Trang khác
import HackValorant from './components/HackValorant/HackValorant';
import ShopAccount from './components/ShopAccount/ShopAccount';
import Payment from './components/Payment/Payment';
import Header from './components/Header/Header';
import HackValorantDetails from './components/HackValorant/HackValorantDetails';

interface ToggleCustomThemeProps {
  showCustomTheme: Boolean;
  toggleCustomTheme: () => void;
}

function ToggleCustomTheme({
  showCustomTheme,
  toggleCustomTheme,
}: ToggleCustomThemeProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100dvw',
        position: 'fixed',
        bottom: 24,
      }}
    >
      {/* <ToggleButtonGroup
        color="primary"
        exclusive
        value={showCustomTheme}
        onChange={toggleCustomTheme}
        aria-label="Platform"
        sx={{
          backgroundColor: 'background.default',
          '& .Mui-selected': {
            pointerEvents: 'none',
          },
        }}
      >
        <ToggleButton value>
          <AutoAwesomeRoundedIcon sx={{ fontSize: '20px', mr: 1 }} />
          Custom theme
        </ToggleButton>
        <ToggleButton value={false}>Material Design 2</ToggleButton>
      </ToggleButtonGroup> */}
    </Box>
  );
}

export default function LandingPage() {
  const [mode, setMode] = React.useState<PaletteMode>('dark');
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  return (
    <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
      <CssBaseline />
      <Router>
        <Header mode={mode} toggleColorMode={toggleColorMode} />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Hero />
                <Box sx={{ bgcolor: 'background.default' }}>
                  {/* <LogoCollection /> */}
                  <Features />
                  <Divider />
                  <Testimonials />
                  <Divider />
                  <Highlights />
                  <Divider />
                  <Pricing />
                  <Divider />
                  <FAQ />
                  <Divider />
                  <Footer />
                </Box>
              </>
            }
          />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/hack-valorant" element={<HackValorant />} />
          <Route path="/hack-valorant/:hackId" element={<HackValorantDetails />} />
          <Route path="/shop-account" element={<ShopAccount />} />
          <Route path="/payment" element={<Payment />} />
        </Routes>
        <ToggleCustomTheme
          showCustomTheme={showCustomTheme}
          toggleCustomTheme={toggleCustomTheme}
        />
      </Router>
    </ThemeProvider>
  );
}
