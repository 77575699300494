export const configHack = [
    {
        hackName: 'Valorant Plus',
        hackPrice: 50000,
        path: 'valorant-plus',
        image: '/images/valorant/valorant-cypher.jpg',
        details: {
            Supported: [
                'Windows 10 All Versions',
                'Windows 11 All Versions',
                `Intel/Amd Cpu's`,
                'Windowed Supported',
                'All Resolutions Supported'
            ],
            Visual: [
                '3D/2D/Cornered Box',
                'Head Box ESP',
                'Distange ESP',
                'Healthbar ESP',
                'View Angle ESP',
                'View Angle ESP',
                'Snapline & Head ESP',
                'Skeleton ESP',
                'Distange ESP',
            ],
            Aimbot: [
                'Draw Fov',
                'Aimlock',
                'Smoothing',
                'Adjustable Fov',
                'Recoil Control',
                'Recoil Crosshair',
                'Visible Check',
            ],
            Misc: [
                'BunnyHop',
                'ESP Preview',
                'vSync Mode',
            ]
        },
        priceDetails: [
            {
                price: 50000,
                time: '1D | 1 Ngày'
            },
            {
                price: 400000,
                time: '1W | 1 Tuần'
            },
            {
                price: 700000,
                time: '1M | 1 Tháng'
            },
            {
                price: 1300000,
                time: 'Liftime | Vĩnh viễn'
            }
        ]
    },
    {
        hackName: 'Valorant Aim Color V1',
        hackPrice: 100000,
        path: 'valorant-aim-color-v1',
        image: '/images/valorant/valorant-jett.jpg'
    },
    {
        hackName: 'Valorant Aim Color V2',
        hackPrice: 100000,
        path: 'valorant-aim-color-v2',
        image: '/images/valorant/valorant-reyna.jpg'
    },
    {
        hackName: 'Valorant Aim Color Pro',
        hackPrice: 100000,
        path: 'valorant-aim-color-pro',
        image: '/images/valorant/valorant-sage.jpg'
    },
    {
        hackName: 'Valorant Only ESP',
        hackPrice: 50000,
        path: 'valorant-aim-color-esp',
        image: '/images/valorant/valorant-killjoy.jpg'
    },
    {
        hackName: 'Valorant Internal',
        hackPrice: 50000,
        path: 'valorant-aim-color-esp',
        image: '/images/valorant/valorant-raze.jpg'
    }
]